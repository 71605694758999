@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    overscroll-behavior-y: none;
}
#app {
  background-image: url('/forest_darkened.png');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; 
}

.stroke-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1) contrast(1);
  transition: filter 0.3s ease;
}

.resume:hover .stroke-icon {
  filter: invert(35%) sepia(100%) saturate(1500%) hue-rotate(90deg) brightness(90%) contrast(100%);
}

underline-effect {
  position: relative; 
  display: inline-block;
}

.underline-effect:hover::after {
  width: calc(100% - 37px);
}.underline-effect {
  position: relative;
  display: inline-block;
}

.underline-effect::after {
  content: '';
  position: absolute;
  left: 17px;
  bottom: -2px;
  height: 2px;
  width: calc(100% - 40px);
  background-color: currentColor;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: bottom right;
}

.underline-effect:hover::after {
  transform: scaleX(1); 
  transform-origin: bottom left;
}

html {
  scroll-behavior: smooth;
}